<template>
	<DsCarousel
		:ariaLabelNext="nextItemsAriaLabel"
		:ariaLabelPrev="previousItemsAriaLabel"
		scrollMode="view"
		data-testid="testimonial-carousel"
		:key="items?.BaseViewModel.length"
		>
		<template #header>
			<h2 class="ds-carousel__title ds-text-heading-3xl ds-text--regular">{{headline}}</h2>
			<p v-if="description" class="ds-carousel__description ds-text-body-md" v-html="description" />
		</template>

		<div v-for="(item, index) in items.BaseViewModel" :key="`item_${index}`" class="c-consumer-testimonials__slide" data-testid="testimonial">
			<figure>
				<template v-if="isVideoBlock">
					<!-- {{ item }} -->
					<consumerTestimonialsVideoBlock :item="item" />
				</template>

				<template v-else>
					<div class="c-consumer-testimonials__slide-picture">
						<img
							v-if="item.ImageUrl"
							:src="item.ImageUrl"
							loading="lazy"
							:alt="item.Caption"/>
					</div>
				</template>

				<figcaption class="c-consumer-testimonials__slide-caption">
					<DsText html-element="div" text-style="regular" text-type="body-xs" class="color-muted">{{ item.Caption }}</DsText>
					<blockquote class="color-muted">
						<DsText html-element="div" text-style="medium" text-type="heading-lg">{{ item.Quote }}</DsText>
					</blockquote>
				</figcaption>
			</figure>
		</div>
	</DsCarousel>
</template>

<script setup>
import { DsCarousel, DsText } from "@coloplast/design-system";
import "@coloplast/design-system/dist/components/carousel/ds-carousel.css";
import { computed } from 'vue';
import consumerTestimonialsVideoBlock from "./consumerTestimonialsVideoBlock.vue"

const props = defineProps({
	headline: {
		type: String,
		required: true,
	},
	description: {
		type: String,
	},
	items: {
		type: Object
	},
	nextItemsAriaLabel: {
		type: String,
		default: "Scroll to the next items"
	},
	previousItemsAriaLabel: {
		type: String,
		default: "Scroll to the previous items"
	}
});

const isVideoBlock = computed(() => !!props.items?.BaseViewModel[0].VideoUrl);

</script>
<style lang="scss" scoped>
:deep .ds-carousel__scroll {
    position: relative;
}
</style>
