<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { DsButtonPlay, DsIconButton } from '@coloplast/design-system';
import SkeletonBox from 'shared/components/skeleton/skeletonBox.vue';
import { youtubeVideoUrl } from 'shared/services/urlTypeService';
import axios from 'axios';
import "@coloplast/design-system/dist/components/button/ds-button-play.css";

const props = defineProps({
	item: {
		type: Object,
		required: true,
	},
});

const testimonialItem = ref(props.item).value;
const iframeEl = ref(null);
let isVideoPlaying = ref(false);
let isClosing = ref(false);
let videoUrl = ref(null);
let playButtonEl = ref(null);

const isVimeo = (videoUrl) => {
	return videoUrl.indexOf('vimeo.com') !== -1;
}

function getYtEmbedUrl(item) {
	const id = item.VideoUrl.indexOf('youtu.be') !== -1;
	let videoId = !id ? /watch\?v=([^$]*)/.exec(item.VideoUrl) : /youtu.be\/([^$]*)/.exec(item.VideoUrl);
	if (!videoId) return;

	videoId = videoId[1].split('&');
	videoId = videoId[0].split('?');

	const embedUrl =
		youtubeVideoUrl() +
		videoId[0] +
		'?enablejsapi=1&autoplay=1&mute=1&modestbranding=1&showinfo=0&rel=0';
	item.embedUrl = embedUrl;

	if (item.ThumbnailImageUrl) {
		item.videoThumbnailUrl = item.ThumbnailImageUrl;
		return;
	}
	const videoThumbnailUrl = `https://img.youtube.com/vi/${videoId[0]}/mqdefault.jpg`;
	item.videoThumbnailUrl = videoThumbnailUrl;
}

async function getVimeoEmbedUrl(item) {
	const id = item.VideoUrl.split('/').pop();
	item.embedUrl = `https://player.vimeo.com/video/${id}?autoplay=1&api=1`;
	if (item.ThumbnailImageUrl) {
		item.videoThumbnailUrl = item.ThumbnailImageUrl;
		return;
	}
	const vimeoJson = `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${id}&format=json`;
	try {
		const response = await axios.get(vimeoJson);
		item.videoThumbnailUrl = response?.data?.thumbnail_url;
	} catch (error) {
		console.log(error);
	}
}

function getVideo() {
	if (!testimonialItem.VideoUrl) return;
	if (isVimeo(testimonialItem.VideoUrl)) {
		getVimeoEmbedUrl(testimonialItem);
	} else {
		getYtEmbedUrl(testimonialItem);
	}
}

const closePopup = () => {
	isClosing.value = true;
	document.body.classList.remove('h-overflow-y-hidden');
	setTimeout(() => {
		videoUrl.value = null;
		isVideoPlaying.value = false;
		isClosing.value = false;
		if (playButtonEl.value) playButtonEl.value.$el.focus();
	}, 200);
};

function playVideo(url) {
	videoUrl.value = url;
	isVideoPlaying.value = true;
	document.body.classList.add('h-overflow-y-hidden');
};

function loadScript() {
	const tag = document.createElement('script');
	tag.src = 'https://www.youtube.com/iframe_api';

	const firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

function onKeydown(event) {
	if (event.key == 'Escape') {
		closePopup();
	}
}

function moveFocusToIframe(e) {
	if(e.key !== "Tab" || e.shiftKey) return;
	e.preventDefault();

	focusIframe();
}

function focusIframe() {
	if (iframeEl.value && iframeEl.value.contentWindow) {
		iframeEl.value.contentWindow.focus();
	}
}

onMounted(() => {
	getVideo();
	if ( document.querySelectorAll('script[src="https://www.youtube.com/iframe_api"]').length == 0) {
		loadScript();
	}

	document.addEventListener('keydown', onKeydown);
});

onBeforeUnmount(() => {
	document.removeEventListener('keydown', onKeydown);
});

</script>

<template>
	<!-- testimonialItem {{ testimonialItem }} -->
	<div
		v-if="testimonialItem?.embedUrl"
		v-on:click.prevent="playVideo(testimonialItem.embedUrl)"
		class="slide-video-placeholder c-consumer-testimonials__slide-picture"
		data-testid="video-placeholder"
	>
		<img
			:src="testimonialItem.videoThumbnailUrl"
			loading="lazy"
			:alt="testimonialItem.Caption"
			data-testid="placeholder-image"
		/>
		<span
			class="slide-play-button"
		>
			<DsButtonPlay
				ref="playButtonEl"
				ariaLabelPlay="Play video"
				size="md">
			</DsButtonPlay>
		</span>
	</div>

	<div v-else data-testid="video-placeholder">
		<SkeletonBox height="100%" />
	</div>

	<Teleport to="body">
		<div
			class="c-consumer-testimonials__video-player"
			:class="{ closing: isClosing }"
			v-on:click.prevent="closePopup"
			data-testid="testimonials-video-player"
			v-if="isVideoPlaying"
		>
			<div class="c-consumer-testimonials__video-player-wrapper">
				<iframe
					ref="iframeEl"
					class="c-video-card__iframe"
					title=""
					:allow="'autoplay; fullscreen; accelerometer; encrypted-media; gyroscope; picture-in-picture'"
					:src="videoUrl"
					data-testid="video-player"
					v-on:load="focusIframe"
				>
				</iframe>
				<span class="c-consumer-testimonials__video-player-close-button">
					<DsIconButton
						icon-name="close"
						v-on:click.prevent="closePopup"
						@keydown="moveFocusToIframe"
						data-testid="close-video"
					/>
				</span>
			</div>
		</div>
	</Teleport>

</template>

<style lang="scss" scoped>
.slide-video-placeholder {
	cursor: pointer;
}
.slide-play-button {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0.9;
}
</style>
