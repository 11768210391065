import { createApp } from 'vue';

import ConsumerTestimonialsCarousel from './consumerTestimonialsCarousel.vue';

import "./consumerTestimonials.scss";

const consumerTestimonialsBlockContainers = document.querySelectorAll(
	"[data-feature='consumerTestimonials']"
);

// Mount Vue components
consumerTestimonialsBlockContainers.forEach((consumerTestimonialsBlockContainer) => {
	const elements: NodeList = consumerTestimonialsBlockContainer.querySelectorAll(
		'[data-consumer-testimonials-content-block]'
	);
	if (elements.length) {
		elements.forEach((element) => {
			const htmlElement: HTMLElement = element as HTMLElement;

			const app = createApp({
				name: htmlElement?.dataset?.blockName,
			});

			app.component('consumer-testimonials-carousel', ConsumerTestimonialsCarousel);
			app.mount(htmlElement);
		});
	}
});